<script setup lang="ts">
import Logo from '~/assets/svg/logo_mobile.svg';
import { ref, useNavigation, useRouter, useHead, useTracker } from '#imports';

interface INavbarProps {
  title?: string;
  subtitle?: string;
  actions?: Array<{ id: string; icon: string; to: string }>;
  backTo?: string;
  withDrawer?: boolean;
  alternate?: boolean;
}

const props = defineProps<INavbarProps>();

const { navigationPath, navigate } = useNavigation();
const router = useRouter();
const { jitsuEvent } = useTracker();

const showDrawer = ref(false);

function onBack() {
  if (props.backTo) {
    navigate(props.backTo);

    return;
  }

  if (window.history.length > 2) {
    router.back();

    return;
  }

  navigate('/');
}

useHead(() => {
  if (showDrawer.value) {
    return {
      htmlAttrs: { class: 'overflow-hidden' },
    };
  }

  return {};
});
</script>

<template>
  <div class="sticky top-0 z-30">
    <div class="relative">
      <ApzHeader
        :title="title"
        :subtitle="subtitle"
        :variant="alternate ? 'solid' : 'gradient'"
        class="!pr-0 !pl-0 relative"
        role="navigation"
      >
        <template
          v-if="title"
          #prefix
        >
          <slot name="prefix">
            <div
              data-testid="navbar-back-button"
              class="flex justify-center items-center text-white h-[40px] w-[40px]"
              @click="onBack"
            >
              <ApzIcon
                :icon="['far', 'chevron-left']"
                fixed-width
                class="text-[20px]"
              />
            </div>
          </slot>
        </template>

        <template
          v-if="!title && !$slots.title"
          #title
        >
          <NuxtLink
            :to="navigationPath('/')"
            external
            no-rel
            aria-label="Link to Home Page"
            class="flex items-center px-15 h-[52px]"
          >
            <Logo
              class="text-white"
              width="80"
            />
          </NuxtLink>
        </template>

        <template
          v-else
          #title
        >
          <slot name="title" />
        </template>

        <template #postfix>
          <div
            data-testid="currency-picker"
            class="flex items-center pr-15"
            @click="
              jitsuEvent('user-click-button', {
                object_name: 'payment-currency',
                object_parameter: 'currency-expand',
              })
            "
          >
            <slot name="action" />
            <!-- <NuxtLink
            v-for="action in actions"
            :key="action.id"
            :to="navigationPath(action.to)"
            class="flex justify-center items-center text-white h-[40px] w-[40px] mr-10"
          >
            <ApzIcon
              :icon="action.icon"
              fixed-width
              class="text-[27px] mt-5"
            />
          </NuxtLink> -->

            <div
              v-if="withDrawer"
              class="h-[17px] w-20 relative"
              data-testid="close-button"
            >
              <div
                class="drawer-toggle w-full h-full absolute top-0 bottom-0 my-auto mx-0"
                data-testid="header-drawer-open"
                :class="{ 'active-menu': showDrawer }"
                @click="showDrawer = !showDrawer"
              >
                <i></i>
              </div>
            </div>
          </div>
        </template>
      </ApzHeader>

      <LazyAppDrawer
        v-if="$device.isCrawler"
        v-show="showDrawer"
        :is-open="showDrawer"
        class="absolute top-50"
      />

      <Transition
        v-else
        name="fade"
      >
        <LazyAppDrawer
          v-if="showDrawer"
          :is-open="showDrawer"
          :class="['absolute', subtitle ? 'top-[60px]' : 'top-50']"
        />
      </Transition>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.drawer-toggle {
  &:after,
  &:before {
    @apply absolute top-0 left-0 right-0 h-3 bg-white w-full;

    content: '';
    transition: all ease-out 0.3s;
  }

  &:after {
    @apply top-full;
  }

  i {
    @apply overflow-hidden h-3 whitespace-nowrap bg-white w-full absolute top-1/2;

    text-indent: 100%;
    transition: all ease-out 0.1s;
  }

  &.active-menu {
    &:after {
      @apply -rotate-45 origin-center top-1/2;
    }

    &:before {
      @apply rotate-45 origin-center top-1/2;
    }

    i {
      @apply opacity-0;
    }
  }
}

.fade-enter-from,
.fade-leave-to {
  @apply transform translate-y-[calc(100vh_-_52px)];
}

.fade-enter-to,
.fade-leave-from {
  @apply transform translate-y-0;
}

.fade-enter-active,
.fade-leave-active {
  @apply transition-all duration-300 ease-out;
}
</style>
